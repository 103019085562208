<template>
  <div class="page-container" v-loading="update_form_loading">
    <page-header></page-header>
    <div class="dashboard-container">
      <div class="page-card">
        <div class="page-card-boody" v-if="deUser">
          <div class="descriptions">
            <div class="descriptions-header">
              <div class="descriptions-title">基本信息</div>
            </div>
            <div class="descriptions-view">
              <table>
                <tbody>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">用户姓名</span>
                      <span class="descriptions-item-content">{{ deUser.name }}</span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">身份证号</span>
                      <span class="descriptions-item-content">{{ deUser.idNo }}</span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">手机号</span>
                      <span class="descriptions-item-content">{{ deUser.phone }}</span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">开发商名称</span>
                      <span class="descriptions-item-content">{{ deUser.developers.name }}</span>
                    </div>
                  </td>
                </tr>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">发电户号</span>
                      <span class="descriptions-item-content">
                        {{ deUser.generatorNo }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">省份</span>
                      <span class="descriptions-item-content">
                        {{ deUser.province }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">银行卡号</span>
                      <span class="descriptions-item-content">
                        {{ deUser.bankNo }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">合同类型</span>
                      <span class="descriptions-item-content">
                        {{ {'02': '屋顶租赁', '03': '全款销售', '01': '个人贷款'}[deUser.type] }}
                      </span>
                    </div>
                  </td>
                </tr>
                <tr class="descriptions-row">
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">经销商名称</span>
                      <span class="descriptions-item-content">
                        {{ deUser.distributor.name }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1">
                    <div class="descriptions-item-container">
                      <span class="descriptions-item-label">电站编号</span>
                      <span class="descriptions-item-content">
                        {{ deUser.powerStationCode }}
                      </span>
                    </div>
                  </td>
                  <td class="descriptions-item" colspan="1"></td>
                  <td class="descriptions-item" colspan="1"></td>
                </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="descriptions">
            <div class="descriptions-header">
              <div class="descriptions-title">更新信息</div>
            </div>
            <div class="descriptions-view">
              <div style="width: 50%;" class="min-w-500">
              <el-form :model="updateForm" :rules="updateRules" label-width="120px" ref="updateForm">
                <el-form-item label="组件块数" prop="size1">
                  <el-input v-model.number="updateForm.size1" :min="1" placeholder="组件块数"></el-input>
                </el-form-item>
                <el-form-item label="还款日" prop="day">
                  <el-select v-model="updateForm.day" placeholder="还款日">
                    <el-option v-for="i in 31" :key="i" :label="i"
                               :value="i"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="并网日期" prop="signTime">
                  <el-date-picker
                      v-model="updateForm.signTime"
                      type="date"
                      value-format="yyyy-MM-dd"
                      placeholder="签订日期">
                  </el-date-picker>
                </el-form-item>
                <el-form-item>
                  <el-button @click="submit('updateForm')" type="primary">生成划转信息</el-button>
                </el-form-item>
              </el-form>
              </div>
            </div>
          </div>
          <div class="descriptions">
            <div class="descriptions-header">
              <div class="descriptions-title">划转信息</div>
            </div>
            <div class="descriptions-view">
              <div v-if="transfer_data" style="width: 100%;box-sizing: border-box;padding-bottom: 50px;">
                <el-tabs v-model="transfer_year" class="demo-tabs">
                  <el-tab-pane v-for="year in transfer_years" :key="year" :label="year" :name="year"></el-tab-pane>
                </el-tabs>
                <div style="padding: 15px;text-align: center">共{{ transfer_data[transfer_year].list.length }}期&nbsp;&nbsp;&nbsp;&nbsp;划转总计：{{
                    transfer_data[transfer_year].sum
                  }}元
                </div>
                <el-table
                    border
                    :data="transfer_data[transfer_year].list"
                    style="width: 100%">
                  <el-table-column
                      align="center"
                      prop="date"
                      label="还款日">
                  </el-table-column>
                  <el-table-column
                      align="center"
                      label="还款金额（元）">
                    <template #default="scope">
                      <el-input v-model="transfer_data[transfer_year].list[scope.$index].money" placeholder="还款金额"
                                style="text-align: center;" @blur="moneyInputBlur(scope.$index)"></el-input>
                    </template>
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader"
import {
  deUserInfo,
  deUserRepaymentUpdate,
  seeTransferInfo,
  seeTransferUpdate
} from "@/api/common"

export default {
  name: "DeUserExamineEdit",
  data() {
    return {
      id: null,
      deUser: null,
      transfer_data: null,
      transfer_years: [],
      transfer_year: '',
      updateForm: {
        userId: '',
        day: '',
        developersId: '',
        signTime: '',
        size1: '',
        distributorId: '',
        roofType: '',
      },
      updateRules: {
        size1: [
          {required: true, type: 'number', min: 0, message: "类型为数字且不能小于0", trigger: "blur"},
        ],
        day: [
          {required: true, message: "请选择还款日", trigger: "blur"},
        ],
        signTime: [
          {required: true, message: "请选择并网日期", trigger: "blur"},
        ],
      },
      update_form_loading: false,
    }
  },
  components: {
    PageHeader,
  },
  created() {
    this.id = this.$route.params.id
  },
  mounted() {
    this.getDeUserInfo()
  },
  methods: {
    getDeUserInfo() {
      let that = this
      that.update_form_loading = true
      deUserInfo(that.id).then(res => {
        that.update_form_loading = false
        that.deUser = res
        this.updateForm = {
          userId: res.id,
          day: res.day,
          developersId: res.developersId,
          signTime: res.signTime,
          size1: res.size,
          distributorId: res.distributorId,
          roofType: res.roofType,
        }
        that.makeTransfer()
      }).catch(() => {
        that.update_form_loading = false
      })
    },
    makeTransfer() {
      let that = this
      seeTransferInfo(this.id).then(res => {
        that.protectMakeTransfer(res)
      })
    },
    submit(formName) {
      let that = this
      that.$refs[formName].validate((valid) => {
        if (valid) {
          that.update_form_loading = true
          seeTransferUpdate(that.updateForm).then(res => {
            that.update_form_loading = false
            that.protectMakeTransfer(res)
          }).catch(() => {
            that.update_form_loading = false
          })
        }
      })
    },
    protectMakeTransfer(res) {
      let that = this
      let years = Object.keys(res)
      let sum_index = years.findIndex(item => {
        return item == 'sum'
      })
      if (sum_index >= 0) {
        years.splice(sum_index, 1)
      }
      if (years.length > 0) {
        let transfer_data = res
        for (let year of years) {
          let list = []
          for (let date in transfer_data[year].data) {
            list.push({
              date: date,
              money: transfer_data[year].data[date]
            })
          }
          transfer_data[year].list = list
        }
        that.transfer_data = transfer_data
        that.transfer_years = years
        that.transfer_year = years[0]
      }
    },
    moneyInputBlur(index) {
      let that = this
      let data = {
        deUserId: that.id,
        repaymentTime: that.transfer_data[that.transfer_year].list[index].date,
        money: that.transfer_data[that.transfer_year].list[index].money || 0,
      }
      this.update_form_loading = true
      deUserRepaymentUpdate(data).then(() => {
        this.update_form_loading = false
      }).catch(() => {
        this.update_form_loading = false
      })
    }
  }
}
</script>

<style scoped>

</style>
